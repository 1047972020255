import { IonIcon } from "@ionic/react";
import playStopIcon from "../../theme/icons/play-stop.svg";
import InfiniteText from "../InfiniteText";
import styles from "./StopPreview.module.css";

type StopCardProps = {
	thumbnail: {
		url: string;
		alt: string;
	};
	title: string;
	artistName: string;
	/**
	 * Media file duration
	 */
	duration?: string;
	/**
	 * Stop's number
	 */
	number: string | number;
};

/**
 * Represents stop's preview which renders thumbnail, title, artist name, media file duration and number
 */
const StopPreview: React.FC<StopCardProps> = ({
	thumbnail,
	title,
	artistName,
	duration,
	number,
}) => {
	return (
		<div className={styles.preview}>
			<img
				className={styles.thumbnail}
				src={thumbnail.url}
				alt={thumbnail.alt}
			/>
			<div className={styles.body}>
				<h2 className={styles.title}>
					<InfiniteText text={title} />
				</h2>
				<p className={styles.artistName}>
					<InfiniteText text={artistName} />
				</p>
				<div className={styles.meta}>
					<p className={styles.duration}>
						{duration && duration.length && (
							<IonIcon
								className="is-small"
								icon={playStopIcon}
								color="primary"
							/>
						)}
						<span className={styles.durationText}>{duration}</span>
					</p>
					<p className={styles.number}>{number}</p>
				</div>
			</div>
		</div>
	);
};

export default StopPreview;
