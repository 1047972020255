import React, { useEffect } from "react";
import { useLocation } from "react-router";
import ReactGa from "react-ga";

/**
 * Sends reports to the google analytics server on init & a route changes.
 * MUST BE placed as a children of the react router inside jsx.
 * Renders a passed in children
 */
const GoogleAnalytics: React.FC = ({ children }) => {
	const location = useLocation();

	// Init google analytics
	useEffect(() => {
		ReactGa.initialize(
			process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE!
		);
	}, []);

	// Send update to dashboard on mount & location changes
	// so we can track were a user now at
	useEffect(() => {
		ReactGa.pageview(window.location.pathname + window.location.search);
	}, [location]);

	return <>{children}</>;
};

export default GoogleAnalytics;
