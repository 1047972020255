import React from "react";
import { useTranslation } from "react-i18next";
import { IonFooter, IonIcon, IonToolbar } from "@ionic/react";
import { ReactComponent as LogoLooolSmall } from "../../theme/icons/logo-loool-small.svg";
import styles from "./TrademarkFooter.module.css";
import classNames from "classnames";

interface TrademarkFooterProps {
	/**
	 * Gradient fade effect at the top edge
	 */
	fadeEffect?: boolean;
}

const TrademarkFooter: React.FC<TrademarkFooterProps> = ({ fadeEffect }) => {
	const { t } = useTranslation();

	return (
		<IonFooter
			className={classNames({
				[styles.footer]: true,
				[styles.topEdgeFade]: fadeEffect,
			})}
		>
			<IonToolbar>
				<p className={styles.credits}>
					<span>
						{t("components.trademark_footer.credits")} &nbsp;
					</span>
					<LogoLooolSmall
						className={styles.looolLogo}
						aria-label="Loool"
					/>
				</p>
			</IonToolbar>
		</IonFooter>
	);
};

export default TrademarkFooter;
