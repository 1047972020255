import {
	IonButton,
	IonContent,
	IonHeader,
	IonIcon,
	IonPage,
	IonText,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter
} from "@ionic/react";
import classNames from "classnames";
import { backspaceOutline, closeCircleOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { openContentDB } from "../contentDB";
import playKeypadIcon from "../theme/icons/play-keypad.svg";
import styles from "./SearchStop.module.css";

/**
 * Page where a user can search a stop by it's number.
 * In case a stop is found then the user will be redirected to the stop page
 */
const SearchStop: React.FC<RouteComponentProps> = ({ history }) => {
	const { t } = useTranslation();
	const [typedNumber, setTypedNumber] = useState("");
	const [showError, setShowError] = useState(false);
	const typedNumberRef = useRef<HTMLDivElement>(null);

	const addDigit = (digit: string) => {
		setTypedNumber(`${typedNumber}${digit}`);
	};

	const removeDigit = () => {
		setTypedNumber(typedNumber.substring(0, typedNumber.length - 1));
	};

	const clear = () => {
		setTypedNumber("");
	};

	const hideError = () => {
		setShowError(false);
	};

	/**
	 * Redirects the user to the stop page in case
	 * a stop is found by the typed number
	 */
	const playStop = async () => {
		const db = await openContentDB();
		const tx = db.transaction("stops", "readonly");
		const store = tx.objectStore("stops");
		const index = store.index("number");
		const retrievedStop = await index.get(typedNumber);

		// If a stop by the typed number is not found
		// then display the error message and reset typed number
		if (!retrievedStop) {
			setTypedNumber("");
			setShowError(true);
			return;
		}

		// Otherwise reset state and redirect the user to the found stop
		setTypedNumber("");
		history.push(`/tabs/stop/${retrievedStop.number}`);
	};

	// Set timer to hide the error message
	// after it was displayed to the user
	useEffect(() => {
		// if show error set to false
		// do nothing
		if (!showError) {
			return;
		}

		const timeout = setTimeout(() => {
			hideError();
		}, 1000);

		// do not forget to cleanup
		return () => clearTimeout(timeout);
	}, [showError]);

	// scroll to the last typed digit
	// Otherwise when typed number is too long and a user types another digit
	// it appears out of view (cause of overflow-x scroll) and creates impresion
	// that the digit was not typed and the keypad is broken
	useEffect(() => {
		if (!typedNumberRef.current || showError) {
			return;
		}

		typedNumberRef.current.scrollLeft =
			typedNumberRef.current.getBoundingClientRect().width;
	}, [typedNumber, showError]);

	useIonViewDidEnter(() => {
		document.title = t("pages.search_stop.title");
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.search_stop.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent scrollEvents={true} fullscreen={true}>
				<div className={styles.container}>
					<div className={styles.output}>
						{showError ? (
							<div className="errorMessageWrapper">
								<IonText color="danger">
									<p
										className={
											styles.invalidNumberErrorMessage
										}
									>
										{t("pages.search_stop.invalid_number")}
									</p>
								</IonText>
							</div>
						) : (
							<div
								className={styles.typedNumber}
								ref={typedNumberRef}
							>
								{typedNumber}
							</div>
						)}

						<IonButton
							className={classNames({
								[styles.playButton]: true,
								[styles.isDisabled]: !Boolean(typedNumber),
							})}
							shape="round"
							aria-label={t(
								"pages.search_stop.search_button_label"
							)}
							// Converts to false in case the typedNumber string is empty
							// otherwise converts to true (including the case "0")
							disabled={!Boolean(typedNumber)}
							onClick={playStop}
						>
							<IonIcon
								className="is-small"
								slot="icon-only"
								icon={playKeypadIcon}
							/>
						</IonButton>
					</div>
					<div className={styles.keypad} onClick={hideError}>
						{["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map(
							(digit) => (
								<IonButton
									key={digit}
									className={styles.keypadButton}
									onClick={() => addDigit(digit)}
									shape="round"
									color="light"
								>
									{digit}
								</IonButton>
							)
						)}
						<IonButton
							className={classNames({
								[styles.keypadButton]: true,
								[styles.removeDigitButton]: true,
							})}
							shape="round"
							onClick={removeDigit}
							color="medium"
							aria-label={t("pages.search_stop.remove_digit")}
						>
							<IonIcon icon={backspaceOutline} />
						</IonButton>
						<IonButton
							className={classNames({
								[styles.keypadButton]: true,
								[styles.clearButton]: true,
							})}
							shape="round"
							onClick={clear}
							color="medium"
							aria-label={t(
								"pages.search_stop.remove_all_digits"
							)}
						>
							<IonIcon icon={closeCircleOutline} />
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SearchStop;
