import { useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";
import { openContentDB } from "../contentDB";
import { StopJson } from "../types";

/**
 * Retrieves the stop from the local database
 */
const useStop = (stopNumber?: string | number) => {
	const [stop, setStop] = useState<StopJson>();

	/**
	 * Toggles like on the current stop
	 *
	 * Returns promise that resolves to true or false
	 * indicating success or fail of the request
	 */
	const toggleLike = async () => {
		// TODO: Handle contentDB error on store.put(...)
		if (!stop) {
			return;
		}

		const isStopHasLike = Boolean(stop.like.token.length);
		const setLikeURL = `${process.env.REACT_APP_REST_API_DOMAIN}/backend/wp-json/pwa/v1/setLike/${stop.ID}`;
		const removeLikeURL = `${process.env.REACT_APP_REST_API_DOMAIN}/backend/wp-json/pwa/v1/removeLike/${stop.like.token}`;

		// Decide if to remove or set like
		const response = await fetch(
			isStopHasLike ? removeLikeURL : setLikeURL,
			{
				method: "post",
			}
		);

		// The request is failed due to http error
		if (!response.ok) {
			return false;
		}

		// setLike returns response with data such as token, created at
		// token is used as a reference to remove particular like
		//
		// remove like has uselesses response ignore it
		const responseJson = await response.json();
		// get store
		const database = await openContentDB();
		const tx = database.transaction("stops", "readwrite");
		const store = tx.objectStore("stops");
		const updatedStop = {
			...stop,
			// in case call was to remove like then reset like properties after the successful rest api call
			// in case call was to set like then set response's json as a value because it contains
			// a token that can be used as a reference to remove particular like
			like: isStopHasLike
				? { post_id: null, created: "", token: "" }
				: responseJson,
		};
		// update stop in contentDB
		await store.put(updatedStop);
		// update stop in memory
		setStop(updatedStop);

		// The request is successful
		return true;
	};

	useIonViewWillEnter(() => {
		if (!stopNumber) {
			return;
		}

		const getStop = async () => {
			const db = await openContentDB();
			const tx = db.transaction("stops");
			const store = tx.objectStore("stops");
			const index = store.index("number");
			const data = await index.get(String(stopNumber));
			setStop(data);
		};
		getStop();
	}, [stopNumber]);

	return {
		stop,
		toggleLike,
	};
};

export default useStop;
