import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewDidEnter } from "@ionic/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, RouteComponentProps } from "react-router-dom";
import TourPreview from "../components/TourPreview";
import useGetTours from "../hooks/useGetTours";
import styles from "./Tours.module.css";

/**
 * Renders available tours
 */
const Tours: React.FC<RouteComponentProps> = ({ match }) => {
	const { t } = useTranslation();
	const { tours } = useGetTours();

	useIonViewDidEnter(() => {
		document.title = t("pages.tours.title");
	});

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.tours.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent scrollEvents={true} fullscreen={true}>
				<div className={styles.grid}>
					{tours.map(({ term_id, name, cover, size }) => (
						<Link
							className={classNames({
								[styles.gridItem]: true,
								[styles.isExpanded]: size === "big",
							})}
							key={term_id}
							to={`${match.url}/${term_id}`}
						>
							<TourPreview
								name={name}
								cover={{
									url: cover.url,
									alt: cover.alt,
								}}
								size={size === "big" ? "lg" : null}
							/>
						</Link>
					))}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Tours;
