import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import arrowRightIcon from "../../theme/icons/sm-arrow-right.svg";
import InfiniteText from "../InfiniteText";
import styles from "./TourPreview.module.css";

interface TourPreviewProps {
	/**
	 * Tour's name to render
	 */
	name: string;
	/**
	 * Cover to render
	 */
	cover: {
		url: string;
		alt: string;
	};
	/**
	 * Tour's preview size, in case the size prop is not passed in
	 * then default size will be used.
	 */
	size?: "lg" | undefined | null;
}

/**
 * Represents preview where cover and name will be rendered.
 */
const TourPreview: React.FC<TourPreviewProps> = ({
	name,
	cover,

	size,
}) => {
	return (
		<div
			className={classNames({
				[styles.tour]: true,
				[styles.isLg]: size === "lg",
			})}
		>
			<div className={styles.textWrapper}>
				<h2 className={styles.name}>
					<InfiniteText text={name} />
				</h2>
				<IonIcon
					style={{
						flexShrink: 0,
					}}
					className="is-small"
					icon={arrowRightIcon}
					color="dark"
				/>
			</div>
			<img className={styles.cover} src={cover.url} alt={cover.alt} />
		</div>
	);
};

export default TourPreview;
