import { useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";
import { openContentDB } from "../contentDB";
import { StopJson, TourJson } from "../types";

/**
 * Retrieves the tour the local database
 */
const useGetTour = (tourId: number | string) => {
	const [tour, setTour] = useState<TourJson>();
	const [tourStops, setTourStops] = useState<StopJson[]>([]);

	useIonViewWillEnter(() => {
		const getTour = async () => {
			const db = await openContentDB();
			const tx = db.transaction(["tours", "stops"]);
			const tourStore = tx.objectStore("tours");
			const tourData = await tourStore.get(Number(tourId));

			// Quick fix for the demo
			// prevent error on the tour object property access
			// if no tour is found by the given id
			//
			// todo: implement 404 page
			if (!tourData) {
				return;
			}

			const stopsStore = tx.objectStore("stops");
			const stopsData: StopJson[] = await Promise.all(
				tourData.stops.map((stopNumber: string) =>
					stopsStore.get(stopNumber)
				)
			);
			setTour(tourData);
			setTourStops(stopsData);
		};
		getTour();
	}, [tourId]);

	return {
		tour,
		tourStops,
	};
};

export default useGetTour;
