import { IonText } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./NoConnection.module.css";

const NoConnection = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.wrapper}>
			<div className={styles.innerWrapper}>
				<h1 className={styles.subtitle}>
					{t("pages.no_connection.subtitle")}
				</h1>
				<p className={styles.text}>
					<IonText>{t("pages.no_connection.message")}</IonText>
				</p>
			</div>
		</div>
	);
};

export default NoConnection;
