import { openContentDB } from "./contentDB";
import { StopJson, TourJson } from "./types";

export default async function loadDemoData(language: string) {
	const toursEndpoint = `${process.env.REACT_APP_REST_API_DOMAIN}/backend/${language}/wp-json/pwa/v1/getTerms`;
	const stopsEndpoint = `${process.env.REACT_APP_REST_API_DOMAIN}/backend/${language}/wp-json/pwa/v1/getAllStops`;
	// Load data
	let tours = await (await fetch(toursEndpoint)).json();
	let stops = await (await fetch(stopsEndpoint)).json();
	// Note: Function openContentDB creates the database if it does not exists
	let connection = await openContentDB();
	let transaction = connection.transaction(["tours", "stops"], "readwrite");
	const toursStore = transaction.objectStore("tours");
	const stopsStore = transaction.objectStore("stops");
	// Clear the database from the old data
	await toursStore.clear();
	await stopsStore.clear();
	// Save the newly loaded data to the database
	tours.forEach((tour: TourJson) => toursStore.put(tour));
	stops.forEach((stop: StopJson) => stopsStore.put(stop));
	await transaction.done;
}
