import React, { useState } from "react";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import PlayPauseButton from "./PlayPauseButton";
import PlayHeadPosition from "./PlayHeadPosition";
import SeekBar from "./SeekBar";
import PlayPauseOverlayIcon from "./PlayPauseOverlayIcon";

type props = {
	/**
	 * The media resource url
	 */
	url: string;
	/**
	 * A node where to render play pause button
	 */
	playPauseButtonNodeContainer?: HTMLElement | null;
	/**
	 * A node where to render play head position
	 */
	playHeadPositionNodeContainer?: HTMLElement | null;
	/**
	 * A node where to render seek bar
	 */
	seekBarNodeContainer?: HTMLElement | null;
};

/**
 * Plays the provided media resource
 *
 * To render the media player controls requires to provide ref to nodes where
 * the controls will be rendered (with react portal)
 */
const MediaPlayer: React.FC<props> = ({
	url,
	playPauseButtonNodeContainer,
	playHeadPositionNodeContainer,
	seekBarNodeContainer,
}) => {
	// This flag is used by the react player to play and pause it's player
	// Problem is that we need to set this flag to false when a user
	// uses the seek bar because otherwise the react player will
	// try to play media resource while the user uses seek bar.
	//
	// So to keep pause icon when the user started to seek while player was in play state
	// we need another flag `isPlaying`
	// Otherwise "play" icon will be showed to the user if he starts to seek when player was in play state
	const [isReactPlayerPlaying, setIsReactPlayerPlaying] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isPlayedBeforeSeek, setIsPlayedBeforeSeek] = useState(false);
	const [progress, setProgress] = useState({
		played: 0,
		playedSeconds: 0,
		loaded: 0,
		loadedSeconds: 0,
	});
	const [duration, setDuration] = useState(0);
	const reactPlayerRef = React.createRef<ReactPlayer>();

	const handleOnSeekBarChange = (secondsToSeek: number) => {
		if (!reactPlayerRef.current) {
			return;
		}

		reactPlayerRef.current.seekTo(secondsToSeek, "seconds");

		if (isReactPlayerPlaying) {
			setIsReactPlayerPlaying(false);
			setIsPlayedBeforeSeek(true);
		}
	};

	const handleOnSeekBarRelease = () => {
		if (isPlayedBeforeSeek) {
			setIsReactPlayerPlaying(true);
			setIsPlayedBeforeSeek(false);
			setIsPlaying(true);
		}
	};

	const togglePlay = () => {
		setIsReactPlayerPlaying((value) => !value);
		setIsPlaying((value) => !value);
	};

	const handleEnded = () => {
		setIsReactPlayerPlaying(false);
		if (!isPlayedBeforeSeek) {
			setIsPlaying(false);
		}
	};

	return (
		<>
			<div onClick={togglePlay}>
				<ReactPlayer
					url={url}
					playing={isReactPlayerPlaying}
					onProgress={setProgress}
					onDuration={setDuration}
					ref={reactPlayerRef}
					progressInterval={0}
					playsinline={true}
					width="100%"
					height="auto"
					onEnded={handleEnded}
				/>
				<PlayPauseOverlayIcon isPlaying={isPlaying} />
			</div>
			{playPauseButtonNodeContainer &&
				ReactDOM.createPortal(
					<PlayPauseButton
						isPlaying={isPlaying}
						onClick={togglePlay}
					/>,
					playPauseButtonNodeContainer
				)}
			{seekBarNodeContainer &&
				ReactDOM.createPortal(
					<SeekBar
						duration={duration > 0 ? duration : 1}
						playedSeconds={progress.playedSeconds}
						onChange={handleOnSeekBarChange}
						onRelease={handleOnSeekBarRelease}
					/>,
					seekBarNodeContainer
				)}
			{playHeadPositionNodeContainer &&
				ReactDOM.createPortal(
					<PlayHeadPosition playedSeconds={progress.playedSeconds} />,
					playHeadPositionNodeContainer
				)}
		</>
	);
};

export default MediaPlayer;
