import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewWillLeave
} from "@ionic/react";
import useOnlineStatus from "@rehooks/online-status";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import useFetch from "use-http";
import HttpError from "../components/HttpError";
import LanguagesList from "../components/LanguagesList";
import NoConnection from "../components/NoConnection";
import PageLoader from "../components/PageLoader";
import { LanguageJson } from "../types";

/**
 * Allows a user to change the current language,
 * after the user has changed language he will be redirected
 * to download, to re-download content in the newly selected language
 */
const ChangeLanguage: React.FC<RouteComponentProps> = ({ history }) => {
	const onlineStatus = useOnlineStatus();
	const { t, i18n } = useTranslation();
	const {
		loading,
		error,
		data = [],
		get,
		abort,
	} = useFetch<LanguageJson[]>(
		`${process.env.REACT_APP_REST_API_DOMAIN}/backend/wp-json/pwa/v1/getLanguages`,
		{
			retries: 3,
		}
	);

	useEffect(() => {
		if (onlineStatus) {
			get();
		}
	}, [onlineStatus, get]);

	useIonViewWillEnter(() => {
		if (loading) {
			return;
		}

		get();
	}, [loading, get]);

	useIonViewDidEnter(() => {
		document.title = t("pages.change_language.title");
	});

	useIonViewWillLeave(() => {
		abort();
	}, [abort]);

	/**
	 * Handles a user language choice
	 *
	 * Sets the selected language as current language
	 * and redirects the user to download.
	 */
	const userSelectLanguage = (language: LanguageJson) => {
		// Re download content is the newly selected language
		i18n.changeLanguage(language.code).then(() => {
			history.push("/download");
		});
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.select_language.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{!onlineStatus && <NoConnection />}
				{onlineStatus && (
					<>
						{loading && <PageLoader />}
						{!loading && error && (
							<HttpError onRetry={() => get()} />
						)}
						{data && !loading && !error && (
							<LanguagesList
								languages={data}
								onSelect={userSelectLanguage}
							/>
						)}
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default ChangeLanguage;
