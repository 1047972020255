import { IonButton, IonIcon } from "@ionic/react";
import React from "react";
import arrowRightIcon from "../../theme/icons/arrow-right.svg";
import engIcon from "../../theme/icons/flag-eng.svg";
import fraIcon from "../../theme/icons/flag-fra.svg";
import itaIcon from "../../theme/icons/flag-ita.svg";
import { LanguageJson } from "../../types";
import styles from "./LanguagesList.module.css";

type LanguagesListProps = {
	/**
	 * The languages to render to a user
	 */
	languages: LanguageJson[];
	/**
	 * Called when a user selects a language
	 */
	onSelect?: (language: LanguageJson) => unknown;
};

/**
 * Renders provided languages as clickable buttons.
 *
 * When a user selects language `onSelect` callback
 * will be called.
 */
const LanguagesList: React.FC<LanguagesListProps> = ({
	languages,
	onSelect,
}) => {
	/**
	 * Returns path to the flag icon of the language
	 */
	const getIcon = (language: LanguageJson) => {
		switch (language.code) {
			case "en": {
				return engIcon;
			}
			case "fr": {
				return fraIcon;
			}
			case "it": {
				return itaIcon;
			}
			default: {
				return "";
			}
		}
	};

	/**
	 * Handles event when a user has selected a language
	 */
	const userHasSelectedLanguage = (language: LanguageJson) => {
		if (!onSelect) {
			return;
		}

		onSelect(language);
	};

	return (
		<ul className={styles.list}>
			{languages.map((language) => (
				<li className={styles.item} key={language.id}>
					<IonButton
						className={styles.button}
						onClick={() => userHasSelectedLanguage(language)}
						expand="block"
						color="light"
					>
						<IonIcon
							className={styles.languageIcon}
							icon={getIcon(language)}
							slot="start"
						></IonIcon>
						<span className={styles.languageName}>
							{language.native_name}
						</span>
						<IonIcon
							className="is-small"
							icon={arrowRightIcon}
							slot="end"
						></IonIcon>
					</IonButton>
				</li>
			))}
		</ul>
	);
};

export default LanguagesList;
