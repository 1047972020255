import { IonSpinner } from "@ionic/react";
import styles from "./PageLoader.module.css";

/**
 * The loader that can be used to indicate that a page content is loading.
 * Is centered on the page through absolute positioning.
 */
const PageLoader = () => (
	<div className={styles.wrapper}>
		<IonSpinner name={"dots"} color={"primary"} />
	</div>
);

export default PageLoader;
