import { useIonViewWillEnter } from "@ionic/react";
import { useState } from "react";
import { openContentDB } from "../contentDB";
import { TourJson } from "../types";

/**
 * Retrieves all the tours from the local database
 */
const useGetTours = () => {
	const [tours, setTours] = useState<TourJson[]>([]);

	useIonViewWillEnter(() => {
		const getTours = async () => {
			const db = await openContentDB();
			const tx = db.transaction("tours");
			const store = tx.objectStore("tours");
			let data = await store.getAll();
			setTours(data);
		};
		getTours();
	});

	return {
		tours,
	};
};

export default useGetTours;
