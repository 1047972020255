import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route } from "react-router-dom";
import CrashScreen from "./components/CrashScreen";
import GoogleAnalytics from "./components/GoogleAnalytics";
import DemoDownload from "./pages/DemoDownload";
import HomeDemo from "./pages/HomeDemo";
import Tabs from "./pages/Tabs";
import "./theme/global.css";
/* Theme variables */
import "./theme/variables.css";

const App: React.FC = () => (
	// 18n translations might still be loaded by the http backend
	// use reacts Suspense
	<Suspense fallback="loading...">
		<ErrorBoundary FallbackComponent={CrashScreen}>
			<IonApp>
				<IonReactRouter>
					<GoogleAnalytics />
					<IonRouterOutlet>
						<Route exact path="/" component={HomeDemo} />
						<Route
							exact
							path="/download"
							component={DemoDownload}
						/>
						<Route path="/tabs" component={Tabs} />
					</IonRouterOutlet>
				</IonReactRouter>
			</IonApp>
		</ErrorBoundary>
	</Suspense>
);

export default App;
