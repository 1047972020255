import React from "react";
import styles from "./WpPost.module.css";

interface WpPostProps {
	/**
	 * Body of the wordpress post
	 */
	body: string;
}

/**
 * Renders Wordpress post with appropriate styles
 */
const WpPost: React.FC<WpPostProps> = ({ body }) => (
	<div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
);

export default WpPost;
