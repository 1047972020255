const fmtMSS = (s: number) => {
	let minutes = (s - (s %= 60)) / 60;
	let seconds = Math.round(s);

	// In case minutes or seconds are less than 10 then add "0" char
	return `${minutes > 9 ? minutes : "0" + minutes}:${
		seconds > 9 ? seconds : "0" + seconds
	}`;
};

export { fmtMSS };
