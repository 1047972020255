import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(Backend)
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		fallbackLng: "it",
		debug: false,
		detection: {
			// order and from where user language should be detected
			order: ["localStorage"],
			// keys or params to lookup language from
			lookupLocalStorage: "i18nextLng",
			// cache user language on
			caches: ["localStorage"],
			excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)
		},
		interpolation: {
			escapeValue: false,
		},
		preload: ["en", "it", "fr"],
	});

export default i18n;
