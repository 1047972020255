import { IonButton, IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CrashScreen.module.css";

/**
 * Shows message to a user and provides a button to restart the app
 */
const CrashScreen: React.FC = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{t("components.crash_screen.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className={styles.content} fullscreen>
				<h2 className={styles.subtitle}>
					{t("components.crash_screen.subtitle")}
				</h2>
				<p className={styles.message}>
					{t("components.crash_screen.message")}
				</p>
				<div className={styles.restartButtonWrapper}>
					{/* 
						Restart the app by refreshing it 
						with the path pointing to the root
						to reset state and errors of the app
					*/}
					<IonButton href="/">
						{t("components.crash_screen.restart_app_button")}
					</IonButton>
				</div>
			</IonContent>
		</div>
	);
};

export default CrashScreen;
