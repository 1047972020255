import { IonButton } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./HttpError.module.css";

interface HttpErrorProps {
	onRetry?: () => unknown;
}

const HttpError: React.FC<HttpErrorProps> = ({ onRetry = () => ({}) }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<div className={styles.innerWrapper}>
				<h2 className={styles.heading}>
					{t("components.http_error.heading")}
				</h2>
				<p className={styles.message}>
					{t("components.http_error.message")}
				</p>
				<IonButton color="primary" onClick={() => onRetry()}>
					{t("components.http_error.retry_button")}
				</IonButton>
			</div>
		</div>
	);
};

export default HttpError;
