import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillEnter,
	useIonViewWillLeave
} from "@ionic/react";
import useOnlineStatus from "@rehooks/online-status";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "use-http";
import HttpError from "../components/HttpError";
import NoConnection from "../components/NoConnection";
import PageLoader from "../components/PageLoader";
import WpPost from "../components/WpPost";
import { WordpressPageJson } from "../types";

const Info: React.FC<RouteComponentProps> = () => {
	const onlineStatus = useOnlineStatus();
	const { t, i18n } = useTranslation();
	const {
		loading,
		error,
		data = null,
		get,
		abort,
	} = useFetch<WordpressPageJson>(
		`${process.env.REACT_APP_REST_API_DOMAIN}/backend/${i18n.language}/wp-json/pwa/v1/getInfoPage`,
		{
			retries: 3,
		}
	);

	useEffect(() => {
		if (onlineStatus) {
			get();
		}
	}, [onlineStatus, get]);

	useIonViewWillEnter(() => {
		if (loading) {
			return;
		}

		get();
	}, [loading, get]);

	useIonViewDidEnter(() => {
		document.title = t("pages.info.title");
	});

	useIonViewWillLeave(() => {
		abort();
	}, [abort]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.info.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent scrollEvents={true} fullscreen={true}>
				{!onlineStatus && <NoConnection />}
				{onlineStatus && (
					<>
						{loading && <PageLoader />}
						{!loading && error && (
							<HttpError onRetry={() => get()} />
						)}
						{data && !loading && !error && (
							<WpPost body={data.body} />
						)}
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Info;
