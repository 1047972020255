import {
	IonButton,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	useIonViewDidEnter,
	useIonViewWillEnter
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteChildrenProps } from "react-router";
import PageLoader from "../components/PageLoader";
import TrademarkFooter from "../components/TrademarkFooter";
import loadDemoData from "../load-demo-data";
import styles from "./DemoDownload.module.css";

const DemoDownload: React.FC<RouteChildrenProps> = ({ history }) => {
	const { t, i18n } = useTranslation();
	const [error, setError] = useState(false);

	const loadData = useCallback(() => {
		setError(false);
		loadDemoData(i18n.language)
			.then(() => {
				history.replace("/tabs/tours");
			})
			.catch((error) => {
				setError(true);
			});
	}, [history, i18n.language]);

	useIonViewDidEnter(() => {
		loadData();
	}, [loadData]);

	useIonViewWillEnter(() => {
		document.title = t("pages.download_content.title");
	}, [t]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">{t("pages.download_content.title")}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent
				className={styles.content}
				scrollEvents={true}
				fullscreen={true}
			>
				{/*  Notify a user about a failed attempt to download content */}
				{/*  and render the button to retry */}
				{error ? (
					<>
						<p className={styles.errorMessage}>
							{t("pages.demo_download.error.message")}
						</p>
						<IonButton onClick={loadData}>
							{t("pages.demo_download.error.retry_button")}
						</IonButton>
					</>
				) : (
					<PageLoader />
				)}
			</IonContent>
			<TrademarkFooter />
		</IonPage>
	);
};

export default DemoDownload;
