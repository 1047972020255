import {
	IonButton,
	IonContent,
	IonIcon,
	IonPage,
	IonSpinner,
	IonText,
	useIonViewWillEnter,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonFooter
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import loadDemoData from '../load-demo-data';
import iconPlay from '../theme/icons/play.svg';
import styles from './HomeDemo.module.css';

const HomeDemo: React.FC<RouteComponentProps> = ({ history }) => {
	const [ loadingData, setLoadingData ] = useState(false);
	const [ loadingDataError, setLoadingDataError ] = useState(false);
	const { i18n } = useTranslation();

	const loadData = useCallback(
		() => {
			setLoadingData(true);
			setLoadingDataError(false);
			loadDemoData(i18n.language)
				.catch(() => {
					setLoadingDataError(true);
				})
				.finally(() => {
					setLoadingData(false);
				});
		},
		[ i18n.language ]
	);

	// Start to loading the data for the demo when the user accesses the page
	useIonViewWillEnter(
		() => {
			loadData();
		},
		[ loadData ]
	);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle slot="start">Audioguida</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className={styles.content}>
				<div className={styles.welcomeMessageBlock}>
					<h1 className={styles.title}>Casa 500</h1>
					<p className={styles.message}>
						Salta a bordo per un viaggio nel tempo attraverso tre generazioni di Fiat 500.
						<span>Buona visita!</span>
					</p>
				</div>
				<div className={styles.bottomBlock}>
					{loadingData ? (
						<IonSpinner name={'dots'} color={'light'} aria-label="Caricamento in corso" />
					) : loadingDataError ? (
						<div>
							<p className={styles.errorMessage}>Si è verificato un errore</p>
							<IonButton expand="block" color="light" onClick={loadData}>
								Riprova
							</IonButton>
						</div>
					) : (
						<IonButton
							className={styles.ctaButton}
							color="dark"
							expand="full"
							onClick={(e) => {
								e.preventDefault();
								// quick workaround for demo
								// to redirect the user directly to the content
								if (i18n.language === 'it') {
									history.replace('/tabs/tours/33');
								} else if (i18n.language === 'en') {
									history.replace('/tabs/tours/34');
								} else if (i18n.language === 'fr') {
									history.replace('/tabs/tours/35');
								} else {
									history.replace('/tabs/tours');
								}
							}}
						>
							Inizia la visita
							<IonIcon className={styles.ctaButtonIcon} icon={iconPlay} />
						</IonButton>
					)}
				</div>
				<IonToolbar className={styles.bottomToolbar} color="dark">
					<small slot="end">
						<IonText color="light">Creato da LOOOL®</IonText>
					</small>
				</IonToolbar>
			</IonContent>
		</IonPage>
	);
};

export default HomeDemo;
