import { deleteDB, openDB, OpenDBCallbacks } from "idb";

const DATABASE_NAME = "content";
const DATABASE_VERSION = 1;

const openContentDB = () => {
	const callbacks: OpenDBCallbacks<unknown> = {
		upgrade(database) {
			if (!database.objectStoreNames.contains("tours")) {
				database.createObjectStore("tours", { keyPath: "term_id" });
			}
			if (!database.objectStoreNames.contains("stops")) {
				const stopsStore = database.createObjectStore("stops", {
					keyPath: "ID",
				});
				stopsStore.createIndex("number", "number", { unique: false });
			}
		},
	};
	const db = openDB(DATABASE_NAME, DATABASE_VERSION, callbacks);
	return db;
};

const deleteContentDB = () => deleteDB(DATABASE_NAME);

export { DATABASE_NAME, DATABASE_VERSION, openContentDB, deleteContentDB };
