import styles from "./PlayHeadPosition.module.css";
import { fmtMSS } from "./utils";

type props = {
	playedSeconds: number;
};

const PlayHeadPosition: React.FC<props> = ({ playedSeconds }) => (
	<span className={styles.time}>{fmtMSS(playedSeconds)}</span>
);

export default PlayHeadPosition;
