import styles from "./PlayPauseButton.module.css";
import playIcon from "../../theme/icons/play.svg";
import pauseIcon from "../../theme/icons/pause.svg";
import { IonIcon } from "@ionic/react";
import classNames from "classnames";

type props = {
	isPlaying: boolean;
	onClick?: (event: React.MouseEvent) => void;
};

const PlayPauseButton: React.FC<props> = ({
	isPlaying,
	onClick = () => {},
}) => (
	<button
		className={styles.button}
		onClick={onClick}
		aria-label={isPlaying ? "Pause" : "Play"}
	>
		<IonIcon
			className={classNames({
				[styles.icon]: true,
				[styles.isHidden]: isPlaying,
			})}
			icon={playIcon}
			color="primary"
		/>
		<IonIcon
			className={classNames({
				[styles.icon]: true,
				[styles.isHidden]: !isPlaying,
			})}
			icon={pauseIcon}
			color="primary"
		/>
	</button>
);

export default PlayPauseButton;
