import { IonButton, IonButtons, IonIcon, IonSearchbar } from "@ionic/react";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./HeaderSearchBar.module.css";
import searchIcon from "../../theme/icons/search.svg";
import closeIcon from "../../theme/icons/close.svg";
import classNames from "classnames";

interface SearchBarProps {
	/**
	 * Placeholder of the search bar input
	 */
	placeholder?: string;
	/**
	 * Emitted when the search bar starts to open
	 */
	onOpen?: () => unknown;
	/**
	 * Emitted when the search bar starts to close
	 */
	onClose?: () => unknown;
	/**
	 * Emitted when a user types
	 */
	onInput?: (keywords: string) => unknown;
	/**
	 * Emitted when a user submits search input (presses the "search"/"go" button on a mobile keyboard or presses the "Enter"/"Return" button on a desktop keyboard)
	 */
	onSearch?: (keywords: string) => unknown;
	/**
	 * Props to pass to the search bar wrapper
	 */
	[key: string]: any;
}

/**
 * Renders a button that opens search bar.
 * Opened search bar will be spawned full width of the header, you can listen for the close and open events
 * to hide other(s) elements in the header.
 * Has close button to close the search button after it was opened.
 *
 * Also provides events to listen for input or search request.
 */
const HeaderSearchBar: React.FC<SearchBarProps> = ({
	placeholder,
	onOpen,
	onClose,
	onInput,
	onSearch,
	...restProps
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const inputRef = useRef<HTMLIonSearchbarElement>(null);
	const openBtnRef = useRef<HTMLIonButtonElement>(null);

	const open = () => {
		setIsOpen(true);
		if (onOpen) onOpen();
	};

	const close = () => {
		setIsOpen(false);
		if (inputRef.current)
			inputRef.current
				.getInputElement()
				.then((input) => (input.value = ""));
		if (onClose) onClose();
	};

	const handleInput = async () => {
		if (!inputRef.current) {
			return;
		}

		const input = await inputRef.current.getInputElement();
		if (onInput) onInput(input.value);
	};

	// Manage the user's focus
	// whenever the user opens or closes the search bar
	useLayoutEffect(() => {
		if (isOpen && inputRef.current) {
			inputRef.current.setFocus();
		}

		if (!isOpen && openBtnRef.current) {
			openBtnRef.current.focus();
		}
	}, [isOpen]);

	useEffect(() => {
		if (!inputRef.current) {
			return;
		}

		const inputRefCurrent = inputRef.current; // suggested by the react hooks rules for cleanup func
		const searchHandler = (event: Event) => {
			const input = event.currentTarget as HTMLInputElement;
			input.blur();
			if (onSearch) onSearch(input.value);
		};

		inputRefCurrent.getInputElement().then((input) => {
			input.addEventListener("search", searchHandler);
		});

		return () => {
			inputRefCurrent
				.getInputElement()
				.then((input) =>
					input.removeEventListener("search", searchHandler)
				);
		};
	}, [onSearch]);

	return (
		<div
			className={classNames({
				[styles.wrapper]: true,
			})}
			{...restProps}
		>
			<IonButtons>
				<IonSearchbar
					className={classNames({
						[styles.searchbar]: true,
						[styles.isVisible]: isOpen,
					})}
					showClearButton={"never"}
					placeholder={placeholder}
					ref={inputRef}
					onInput={handleInput}
				/>
				<IonButton
					className={classNames({
						[styles.open_button]: true,
						[styles.alignedLeft]: isOpen,
					})}
					onClick={open}
					ref={openBtnRef}
				>
					<IonIcon
						className="is-medium"
						icon={searchIcon}
						slot="icon-only"
					/>
				</IonButton>
				<IonButton
					className={classNames({
						[styles.close_button]: true,
						[styles.isVisible]: isOpen,
					})}
					onClick={close}
				>
					<IonIcon
						className="is-medium"
						icon={closeIcon}
						slot="icon-only"
					/>
				</IonButton>
			</IonButtons>
		</div>
	);
};

export default HeaderSearchBar;
